<template>
  <main>
    <section class="login-form">
      <div class="login-form-inner">
        <img :src="thdLogo" class="thd-logo" width="70" alt="thd logo">
        <h2 class="header-2">Forgot password?</h2>
        <p>Don’t worry! It happens. Please enter the HD email address associated with your account.</p>
        <SimpleInput
          label="Email Address"
          v-model="email"
          placeholder-text="@thehomedepot.com"
          placeholder-align="right"
        />
        <SimpleButton label="Submit" full-width @click="sendEmail" style="margin-top: 100px;" />
      </div>
    </section>
    <SloganLetsBuild />
  </main>
</template>

<script setup>
import { ref } from 'vue'
import SimpleInput from '@/components/ui/SimpleInput'
import thdLogo from '../assets/images/THD_Logo 1.png'
import SimpleButton from '@/components/ui/SimpleButton'
import SloganLetsBuild from '@/components/SloganLetsBuild'

const email = ref('')

const sendEmail = async () => {
  if (!email.value) {
    alert('Please fill in all fields!')
    return
  }
  console.log('Email sent!')
}
</script>

<style scoped>
main {
  display: flex;
  min-height: 100vh;
}

.login-form {
  display: flex;
  justify-content: center;
  width: 48%;
}

.login-form-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 320px;
  height: 100%;
}

.thd-logo {
  margin-top: 20px;
  margin-bottom: 50px;
}

.header-2 {
  margin-top: 0;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  font-size: 40px;
  color: black;
}
</style>
